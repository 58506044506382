//React

import React from "react";

function Psychologist() {

  React.useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "instant"
    });
    document.title = process.env.REACT_APP_NAME + ' - Pszichológus';
  }, []);

  return (
    <section id="psychologist">
      <div className="first-row flex flex-col lg:flex-row items-center gap-8 relative z-10">
        <img src="/assets/images/psychologist.jpg" alt="psychologist" className="max-h-[25rem] lg:min-w-[36rem]" />
        <div className="second-col pl-6 pr-6 lg:pr-0 lg:pl-0 mb-8 lg:mb-16">
          <p className="text-10 lg:text-14">
            A Pázmány Péter Katolikus Egyetemen szereztem diplomát pszichológusként igazságügyi és klinikai programon, majd autogén tréner lettem 2013-ban.
            Szemléletemben, szakmai elköteleződésemben meghatározó erővel bír a jungi pszichoanalízis. Közel hét évet töltött*em el igazságügyi, alkalmazott területen, ahol munkám során több alkalommal dolgoztam krízisben lévő, hangulatzavarral, szorongással, személyiségzavarral és addiktológiai problémákkal küzdő kliensekkel.
          </p>
          <p className="text-10 lg:text-14">
            2017-ben klinikai szakvizsgát tettem, mindemellett jungi analitikus módszerben képződöm, autogén tréner képesítésem mellé mindfulness tanári végzettséget szereztem 2022-ben. Szorongásos, depressziós zavarokban ezek rendkívül hasznos módszerek.
          </p>
        </div>
      </div>
      <div className="second-row flex flex-col lg:flex-row gap-8 -z-10 lg:ml-24 lg:-mt-10">
        <div className="first-col bg-primary rounded-none lg:rounded-1.625 lg:min-w-[33rem] lg:h-[17rem]">
          <p className="text-12 lg:text-15 p-6 lg:p-12">
            <strong>A forgalommal, vezetéssel kapcsolatban,</strong> főleg az autóvezetés elsajátításának folyamatában jelentkezhet izgalom, izgatottság, amelyek teljesen normálisak, de akár szorongásos tünetek is megjelenhetnek. Ezek kezeléséhez technikák elsajátításában, már korábban esetlegesen kialakult pánik kezelésében tudom támogatni, klinikai szakpszichológusként.
          </p>
        </div>
        <div className="second-col pl-6 pr-6 lg:pr-0 lg:pl-0">
          <p className="mb-6 text-10 lg:text-14">
            Dolgoztam a büntetés-végrehajtás oktatási központjában, ahol az elméleti pszichológia oktatás mellett kommunikációs, asszertivitás és konfliktuskezelő tréningeket tartottam kolléganőmmel, majd a Büntetés-végrehajtás Országos Parancsnokság kipróbáltam magam a szakirányítás területén is. Jelenleg egészségügyi főállásom és doktori tanulmányaim mellett kisebb óraszámban magánrendelek.
          </p>
          <div className="contact">
            <p className="font-bold mb-2 text-12 lg:text-15">
              Kérdés esetén fordulj hozzám bizalommal:
            </p>
            <p className="font-bold mb-1 text-12 lg:text-15">
              Koday Zsuzsanna
            </p>
            <a href="mailto:info@kodayzsuzsanna.hu" className="underline text-10 lg:text-14">
              info@kodayzsuzsanna.hu
            </a>
            <a href="https://www.kodayzsuzsanna.hu" target="_blank" className="underline text-10 lg:text-14">
              www.kodayzsuzsanna.hu
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Psychologist;
