//React

import React from "react";

function Impressum() {

    React.useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "instant"
        });
        document.title = process.env.REACT_APP_NAME + ' - Elsősegély';
    }, []);

    return (
        <section id="elsosegely" className="w-full max-w-viewport pl-6 pr-6 2xl:pr-0 2xl:pl-0">
            <div className="flex flex-row gap-4">
                <div className="flex flex-col">
                    <div className="bg-primary p-9 rounded-1.625 w-full">
                        <h2 className="font-bold uppercase text-16 lg:text-21 mb-3">Elsősegély</h2>
                        <div className="flex flex-col lg:flex-row gap-8">
                            <div className="flex flex-col gap-4">
                                <p className="text-12 lg:text-14">
                                    Az elsősegély tanfolyamon való részvétel nem kötelező, de erősen ajánlott a sikeres EÜ vizsga érdekében.
                                </p>
                                <p className="text-12 lg:text-14">
                                    <strong>Jelentkezni e-mailben</strong> lehet az <br /><strong><a href="mailto:info@rajtautosiskola.hu" className=" hover:text-secondary">info@rajtautosiskola.hu</a> e-mail címen</strong> az időpont, név és telefonszám megadásával.
                                </p>
                            </div>
                            <div className="flex flex-col justify-start min-w-fit">
                                <p className="text-11">Következő oktatás időpontja:</p>
                                <div className="flex flex-row gap-2 items-center">
                                    <img src="/assets/logos/calendar.png" alt="calendar" className="h-10 w-10 lg:h-14 lg:w-14" />
                                    <p className="text-20 lg:text-27 font-bold">2025.04.05</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pl-9 pr-9 mt-4">
                        <p className="text-12 lg:text-14"><strong>Az oktatás díja:</strong> 20.000 Ft</p>
                        <p className="text-12 lg:text-14"><strong>Létszám:</strong>  Minimum 5 fő, maximum 10 fő / alkalom</p>
                        <p className="text-12 lg:text-14"><strong>Az oktatás időpontja és időtartama:</strong> Az oktatásokat <strong>szombati napokon 9 és 13 óra között tartjuk.</strong></p>
                    </div>
                </div>
                <img className="hidden lg:block" src="/assets/images/eu/box.png" alt="eu-box" />
            </div>
            <div className="flex flex-col lg:flex-row mt-6 gap-4 items-center">
                <div className="min-w-fit flex flex-row mt-10">
                    <img src="/assets/images/eu/instructor_2.jpg" alt="instructor_2" className="h-52 w-52 object-cover rounded-1.625 border-white border-4 relative -mt-10" />
                    <img src="/assets/images/eu/instructor_1.jpg" alt="instructor_1" className="h-48 w-36 object-cover rounded-1.625 border-white border-4 relative -ml-20" />
                </div>
                <div className="mt-auto">
                    <p>
                        Az egészségügyi tanfolyamot <strong>Szlobodnyik Balázs</strong> tartja majd. <strong>Kollégánk 15 éve foglalkozik elsősegélynyújtással, 10 évig mentőápolóként dolgozott, az elmúlt 5 évben pedig hivatásosoknak tartott taktikai elsősegélyt.</strong><br />
                        Fontosnak tartjuk, hogy az elsősegély ne csak egy letudandó vizsga, az elsősegély vizsga felkészítő pedig ne csak egy letudandó oktatási nap legyen, hanem valóban hasznos, a mindennapi életünk során használható, tudást adjon.
                        Az oktatásokon Balázs igyekszik mindig kötetlen hangulatú, gyakorlatorientált órákat tartani, melyeken el tudjátok majd sajátítani az újraélesztés és az elsősegélynyújtás lépéseit, hogy azokat szükség esetén bátran tudjátok használni az életben.
                    </p>
                </div>
            </div>
            <h2 className="font-bold text-16 lg:text-21 mb-8 mt-8">Néhány gyakorlati jótanács az elsősegély vizsga felkészítő oktatási napra:</h2>
            <div className="flex flex-col lg:flex-row gap-4 justify-center items-center">
                <div className="flex flex-col items-center justify-center max-w-64 h-44 text-center gap-2 bg-card p-4 rounded-1.625">
                    <img src="/assets/logos/notepad.png" alt="notepad" className="h-10 w-10 lg:h-12 lg:w-12" />
                    <p>Az oktatásra kérlek, hozz magaddal tollat, jegyzetfüzetet.</p>
                </div>
                <div className="flex flex-col items-center justify-center  max-w-64 h-44 text-center gap-2 bg-card p-4 rounded-1.625">
                    <img src="/assets/logos/clothes.png" alt="clothes" className="lh-10 w-10 g:h-12 lg:w-12" />
                    <p>Kényelmes ruhában gyere (gyakorláshoz megfelelő, zárt sportcipő, nadrág, póló).</p>
                </div>
                <div className="flex flex-col items-center justify-center  max-w-64 h-44 text-center gap-2 bg-card p-4 rounded-1.625">
                    <img src="/assets/logos/hamburger.png" alt="hamburger" className="h-auto w-10 lg:w-12" />
                    <p>Mivel hosszú ideig tart az oktatás, hozz magaddal inni és ennivalót.</p>
                </div>
                <div className="flex flex-col items-center justify-center  max-w-64 h-44 text-center gap-2 bg-card p-4 rounded-1.625">
                    <img src="/assets/logos/smile.png" alt="smile" className="h-10 w-10 lg:h-12 lg:w-12" />
                    <p>Semmiképpen se felejtsd otthon a jó kedvedet!</p>
                </div>
            </div>
            <div>
                <p className="text-12 lg:text-14 mb-6 mt-4">
                    A gyakorlati feladatokkal egymáson fogunk gyakorolni, ahogy a vizsgán is egymáson fogjátok bemutatni a feladatokat.<br /> Ehhez le kell majd feküdni (takaró lesz a földön), le kell térdelni stb. Kérlek, ezzel számoljatok.
                </p>
                <p className="font-bold text-12 lg:text-14">
                    A tananyagot kinyomtatott formában a rendelkezésedre bocsájtjuk.
                </p>
                <p className="text-12 lg:text-14">
                    Kérjük, amennyiben <strong>nem tudsz megjelenni az oktatáson, az időpont előtt legalább 48 órával jelezd az <a href="mailto:info@rajtautosiskola.hu" className=" hover:text-secondary">info@rajtautosiskola.hu</a> e-mail címen.</strong>
                </p>
            </div>
            <div className="bg-primary w-full rounded-1.625 p-9 mt-12">
                <h2 className="font-bold uppercase text-16 lg:text-21 mb-3">Elsősegély vizsga</h2>
                <p className="text-12 lg:text-14 mb-2">A jelentkezés az elsősegélynyújtó vizsgákra minden alkalommal internetes felületen történik:</p>
                <a className="text-16 lg:text-21 text-red-500 hover:text-secondary break-all" href="https://pestvoroskereszt.hu/elsosegely/vizsgak" target="_blank">
                    https://pestvoroskereszt.hu/elsosegely/vizsgak
                </a>
                <p className="text-12 lg:text-14 italic mt-1">
                    A 7. területet alatt találhatóak a váci vizsgák.
                </p>
                <p className="text-12 lg:text-14 italic">
                    Jelentkezési kisokos:
                    <a className="ml-1 hover:text-secondary break-all" href="https://pestvoroskereszt.hu/media/vizsga_jelentkezes/elsosegely_vizsgakalauz.pdf" download target="_blank">
                        https://pestvoroskereszt.hu/media/vizsga_jelentkezes/elsosegely_vizsgakalauz.pdf
                    </a>
                </p>
            </div>
            <div className="mt-6">
                <p className="mb-4 text-12 lg:text-14 font-bold">
                    Amennyiben a jelentkezés után nem érkezik meg a visszaigazolás 24 órán belül, kérjük, hogy jelezd a területi illetékesnek:
                </p>
                <p className="text-12 lg:text-14">
                    <strong>Talapa Lili </strong><br />
                    Területi munkatárs,  <br />
                    Elsősegélynyújtó tanfolyam- és vizsgaszervező  <br />
                    Magyar Vöröskereszt Pest Vármegyei Szervezete  <br />
                    Mobil: +36 (70) 933 8195 | E-mail: <a href="mailto:lili.talapa@voroskereszt.hu" className=" hover:text-secondary">lili.talapa@voroskereszt.hu</a>
                </p>
                <p className="text-12 lg:text-14 mt-8">
                    A jelentkezés után egy automatikusan generált e-mailben tájékoztatnak majd, hogy várhatóan mikor fogsz sorra kerülni. <br />
                    Ezelőtt az időpont előtt elég 15 perccel megjelenned, illetve az alábbiakat mindenképpen vidd magaddal:
                </p>
                <ul className="ml-8 mt-2">
                    <li className="flex flex-row gap-2 items-center">
                        <img src="/assets/logos/dot.png" alt="dot-img" />
                        <p className="text-12 lg:text-14">érvényes személyi igazolvány / útlevél</p>
                    </li>
                    <li className="flex flex-row gap-2 items-center">
                        <img src="/assets/logos/dot.png" alt="dot-img" />
                        <p className="text-12 lg:text-14">kitöltött regisztrációs lap</p>
                    </li>
                    <li className="flex flex-row gap-2 items-center">
                        <img src="/assets/logos/dot.png" alt="dot-img" />
                        <p className="text-12 lg:text-14">18 év alattiaknak, a szülő által aláírt beleegyező nyilatkozat</p>
                    </li>
                    <li className="flex flex-row gap-2 items-center">
                        <img src="/assets/logos/dot.png" alt="dot-img" />
                        <p className="text-12 lg:text-14">csekkes befizetés esetén a befizetést igazoló csekkszelvény</p>
                    </li>
                    <li className="flex flex-row gap-2 items-center">
                        <img src="/assets/logos/dot.png" alt="dot-img" />
                        <p className="text-12 lg:text-14">utalás esetén: a bank által aláírt és lepecsételt igazolás a befizetésről</p>
                    </li>
                </ul>
                <p className="text-12 lg:text-14 mt-8">
                    <strong>Ha nem tudsz elmenni</strong> a vizsgára önhibádon kívül, azt még a vizsga kezdete előtt jelezd a területi munkatársnak emailben. Amennyiben a következő vizsgán felmutatod az orvosi igazolást, úgy nem kell befizetni újra a vizsgadíjat.
                    Ebben az esetben területi munkatárs tud bejelenteni egy általad választott másik vizsgára. Minden más esetben újra be kell fizetni a vizsgadíjat.
                </p>
                <p className="text-12 lg:text-14 mt-8">
                    Vizsga alól csak és kizárólag felsőfokú egészségügyi végzettséggel rendelkezők mentesülhetnek. Mentességi igazolást csak a vármegyei elsősegélynyújtó koordinátor állíthat ki, illetve a területi munkatárs tud segíteni ebben.
                </p>
            </div>
        </section>
    );
}

export default Impressum;
