//React

import React from "react";

function GetLicence() {

  React.useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "instant"
    });
    document.title = process.env.REACT_APP_NAME + ' - Szerezz jogsit!';
  }, []);

  return (
    <>
      <section id="documents" className="w-full max-w-viewport pl-6 pr-6 2xl:pr-0 2xl:pl-0">
        <h2 className="font-extrabold text-center text-17 2xl:text-19 mb-10">Nehezen látod át a jogosítványszerzés folyamatát?</h2>
      </section>
      <div className="flex flex-col gap-1">
        <div className="flex flex-row items-center gap-4 lg:gap-8">
          <div className="w-12 lg:w-16 flex flex-row items-center justify-center">
            <img src="/assets/logos/gray-circle.png" alt="circle" className="h-8 lg:h-12" />
          </div>
          <div className="flex flex-col">
            <p className="text-secondary font-bold text-10">1. LÉPÉS</p>
            <p className="text-12 lg:text-14 font-bold">Jelentkezés</p>
          </div>
        </div>
        <div className="flex flex-row items-center gap-4 lg:gap-8">
          <div className="w-12 lg:w-16 flex flex-row items-center justify-center">
            <span className=" border border-secondary h-8"></span>
          </div>
        </div>
        <div className="flex flex-row items-center gap-4 lg:gap-8">
          <div className="w-12 lg:w-16 flex flex-row items-center justify-center">
            <img src="/assets/logos/gray-circle.png" alt="circle" className="h-8 lg:h-12" />
          </div>
          <div className="flex flex-col">
            <p className="text-secondary font-bold text-10">2. LÉPÉS</p>
            <p className="text-12 lg:text-14 font-bold">KRESZ tanfolyam</p>
          </div>
        </div>
        <div className="flex flex-row items-center gap-4 lg:gap-8">
          <div className="w-12 lg:w-16 flex flex-row items-center justify-center">
            <span className=" border border-secondary h-8"></span>
          </div>
        </div>
        <div className="flex flex-row items-center gap-4 lg:gap-8">
          <div className="w-12 lg:w-16 flex flex-row items-center justify-center">
            <img src="/assets/logos/gray-circle.png" alt="circle" className="h-8 lg:h-12" />
          </div>
          <div className="flex flex-col">
            <p className="text-secondary font-bold text-10">3. LÉPÉS</p>
            <p className="text-12 lg:text-14 font-bold">KRESZ vizsga</p>
          </div>
        </div>
        <div className="flex flex-row items-center gap-4 lg:gap-8">
          <div className="w-12 lg:w-16 flex flex-row items-center justify-center">
            <span className=" border border-secondary h-8"></span>
          </div>
        </div>
        <div className="flex flex-row items-center gap-4 lg:gap-8">
          <div className="w-12 lg:w-16 flex flex-row items-center justify-center">
            <img src="/assets/logos/gray-circle.png" alt="circle" className="h-8 lg:h-12" />
          </div>
          <div className="flex flex-col">
            <p className="text-secondary font-bold text-10">4. LÉPÉS</p>
            <p className="text-12 lg:text-14 font-bold">Gyakorlati oktatás</p>
          </div>
        </div>
        <div className="flex flex-row items-center gap-4 lg:gap-8">
          <div className="w-12 lg:w-16 flex flex-row items-center justify-center">
            <span className=" border border-secondary h-8"></span>
          </div>
        </div>
        <div className="flex flex-row items-center gap-4 lg:gap-8">
          <div className="w-12 lg:w-16 flex flex-row items-center justify-center">
            <img src="/assets/logos/gray-circle.png" alt="circle" className="h-8 lg:h-12" />
          </div>
          <div className="flex flex-col">
            <p className="text-secondary font-bold text-10">5. LÉPÉS</p>
            <p className="text-12 lg:text-14 font-bold">Forgalmi vizsga</p>
          </div>
        </div>
        <div className="flex flex-row items-center gap-4 lg:gap-8">
          <div className="w-12 lg:w-16 flex flex-row items-center justify-center">
            <span className=" border border-secondary h-8"></span>
          </div>
        </div>
        <div className="flex flex-row items-center gap-4 lg:gap-8">
          <div className="w-12 lg:w-16 flex flex-row items-center justify-center">
            <img src="/assets/logos/primary-checked.png" alt="circle" className="h-10 lg:h-14" />
          </div>
          <div className="flex flex-col">
            <p className="text-secondary font-bold text-10">6. LÉPÉS</p>
            <p className="text-12 lg:text-14 font-bold">SIKER!</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default GetLicence;
